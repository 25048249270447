@import "../mixins/_input-placeholder.mixin";

form.form\:narrow {
	.input-group {
		width: 100%;
	}
}

.form-section {
	@extend .mb\:12;
}

%shared-input-group {
	display: flex;
	align-items: center;
	transition: all 100ms ease-in-out;

	label:first-child {
		@extend .text\:2xs, .text\:extra-bold, .ml\:1, .mr\:5, .text\:right;
		text-transform: uppercase;
		letter-spacing: 0.01rem;
		width: 75px;
		flex-shrink: 0;
	}

	a {
		text-decoration: underline;
	}

	input[type="checkbox"] {
		flex: none;
		align-self: first baseline;
		margin-left: map-get($spacings, 3);
		// display: none;

		& + label {
		 	@extend .text\:xs, .ml\:3;
			text-align: left;
			line-height: map-get($spacings, 5);
			padding: 2px;
		}
	}

	input {
		flex: 1;
		background: none;
		border-color: transparent;
		position: relative;
		outline: none;
		height: map-get($spacings, 6);
		line-height: map-get($spacings, 6);

		&[type="password"] {
			font-family: Verdana;

			@include placeholder {
				font-family: Verdana;
			}
		}

		&.hide-placeholder {
			@include placeholder {
				opacity: 0;
			}
		}

		@include placeholder {
			opacity: 1;
			font-size: 80%;
			-moz-transition: all 500ms ease-in-out;
			transition: all 500ms ease-in-out;
		}
	}
}

.input-group {
	@extend %shared-input-group, .mb\:2;

	padding: map-get($spacings, 2) map-get($spacings, 3);
	border-radius: 4px;

	&.StripeElement {
		padding: map-get($spacings, 3) map-get($spacings, 2)
			map-get($spacings, 3) map-get($spacings, 4);
		& > * {
			flex: 1;
		}
	}
}

.form-group {
	@extend %shared-input-group, .mb\:2;

	padding: 0;
	border-radius: 4px;

	display: flex;
	flex-direction: column;

	%status-msg {
		@extend .text\:xs, .text\:left;
		padding: map-get($spacings, 3) map-get($spacings, 4);
		line-height: 1.25rem;
		border-radius: 4px 4px 0 0;
		border: solid 1px transparent;
		opacity: 1;
		display: none;
		box-sizing: content-box;
		height: 0px;
		overflow: hidden;
		transition: all 0.3s ease-out;

		h4, p {
			display: inline;
		}

		&.hidden {
			opacity: 0;
			padding: 0 map-get($spacings, 4);
		}

		&.hidden:first-of-type + .input-group {
			border-radius: 4px 4px 0 0;
		}
	}

	.status-msg\:error {
		@extend %status-msg, .color\:primary-500, .bg\:primary-100;
		border-color: map-get($colors, primary-400);
	}

	.input-group {
		margin-bottom: 0;
		box-shadow: none;
		border-radius: 0;
		position: relative;
	}

	:not(.status-msg\:error) + .input-group:not(:first-of-type):before {
		content: " ";
		top: 0;
		left: 110px;
		right: 0;
		border-top-width: 1px;
		border-top-style: solid;
		position: absolute;
		transition: all 100ms ease-in-out;
	}

	.input-group-separator\:100.input-group:not(:first-of-type):before {
		left: 0;
	}

	.input-group:first-child {
		border-radius: 4px 4px 0 0;
	}

	.input-group:last-child {
		border-radius: 0 0 4px 4px;
	}
}

@mixin _input_colors(
	$mode,
	$bg_color,
	$shadow_color,
	$label_color,
	$input_color,
	$placeholder_color,
	$focus_bg_color,
	$focus_color,
	$focus_label_color
) {
	#{$mode} .input-group {
		background-color: map-get($colors, $bg_color);
		box-shadow: 0 2px 4px 0 $shadow_color;

		label {
			color: map-get($colors, $label_color);
		}

		input {
			color: map-get($colors, $input_color);

			@include placeholder {
				color: map-get($colors, $placeholder_color);
			}
		}

		&:focus-within,
		&.StripeElement--focus {
			background-color: map-get($colors, $focus_bg_color);
			color: map-get($colors, $focus_color);

			label {
				color: map-get($colors, $focus_label_color);
			}

			& + .input-group:before {
				border-top-color: map-get($colors, $bg_color) !important;
			}
		}
	}
	#{$mode} .form-group {
		@extend .mb\:2;
		background-color: map-get($colors, $bg_color);
		box-shadow: 0 2px 4px 0 $shadow_color;

		.input-group:not(:first-child):before {
			border-top-color: map-get($colors, $focus_bg_color);
		}

		.input-group {
			box-shadow: none;
		}
	}
}

@include _input_colors(
	"",
	$bg_color: neutral-100,
	$shadow_color: rgba(0, 0, 0, 0.3),
	$label_color: neutral-500,
	$input_color: neutral-600,
	$placeholder_color: neutral-400,
	$focus_bg_color: neutral-200,
	$focus_color: neutral-700,
	$focus_label_color: neutral-600
);

@include _input_colors(
	".bg-dark",
	$bg_color: neutral-700,
	$shadow_color: rgba(0, 0, 0, 0.5),
	$label_color: neutral-300,
	$input_color: neutral-300,
	$placeholder_color: neutral-500,
	$focus_bg_color: neutral-600,
	$focus_color: neutral-700,
	$focus_label_color: neutral-200
);

button {
	border: none;
	height: 39px;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.4);
	border-radius: 4px !important;
	letter-spacing: -0.4px;
	color: white;
}
