@each $spacing, $value in $spacings {
	.mt\:#{$spacing} {
		margin-top: $value;
	}
	.mb\:#{$spacing} {
		margin-bottom: $value;
	}
	.mr\:#{$spacing} {
		margin-right: $value;
	}
	.ml\:#{$spacing} {
		margin-left: $value;
	}
	.my\:#{$spacing} {
		margin-top: $value;
		margin-bottom: $value;
	}
	.pb\:#{$spacing} {
		padding-bottom: $value;
	}
	.pt\:#{$spacing} {
		padding-top: $value;
	}
	.py\:#{$spacing} {
		padding-top: $value;
		padding-bottom: $value;
	}
	.space\:x-#{$spacing} {
		& > *:not(:last-child) {
			margin-right: $value;
		}
	}
}
