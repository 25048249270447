@import "../_variables";

$font-family-sans: "Open Sans", sans-serif;

@each $size-name, $size in $font-sizes {
	.text\:#{$size-name} {
		font-size: $size;
	}
}

.text\:nowrap {
	white-space: nowrap;
}

.text\:left {
	text-align: left;
}

.text\:center {
	text-align: center;
}

.text\:right {
	text-align: right;
}

.text\:regular {
	font-weight: normal;
}

.text\:bold {
	font-weight: bold;
}

.text\:extra-bold {
	font-weight: 900;
}

.text\:italic {
	font-style: italic;
}

.text\:uppercase {
	text-transform: uppercase;
}

.text\:strike-through {
	text-decoration: line-through double;
}

.text\:new-price {
	@extend .text\:3xl, .text\:bold;

	.currency {
		@extend .text\:lg;
		position: relative;
		top: -10px;
		left: -2px;
	}
}

.text\:old-price {
	@extend .text\:lg, .color\:neutral-500;
	position: relative;

	&:before {
		position: absolute;
		content: "";
		left: -6px;
		top: 50%;
		right: -10px;
		border-top: 1px solid;
		border-color: inherit;
		/* -webkit-transform: rotate(-5deg); */
		$angle: -18deg;
		-moz-transform: rotate($angle);
		-ms-transform: rotate($angle);
		-o-transform: rotate($angle);
		transform: rotate($angle);
	}

	.currency {
		@extend .text\:2xs;
		position: relative;
		top: -5px;
		left: -2px;
	}
}
