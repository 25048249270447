@import "_variables.scss";

@import "base/_colors.scss";
@import "base/_spacings.scss";
@import "base/_container.scss";
@import "base/_typography.scss";
@import "base/_position.scss";

@import "components/_buttons.scss";
@import "components/_cards.scss";
@import "components/_forms.scss";
@import "components/_quotes.scss";
@import "components/_menu.scss";
@import "components/_spinners.scss";

@import "elements/_headings.scss";
@import "elements/_paragraphs.scss";

* {
	margin: 0;
	padding: 0;
	letter-spacing: -0.01rem;
	box-sizing: border-box;
}

body {
	@extend .text\:base;
	font-family: $font-family-sans;
	-webkit-font-smoothing: antialiased;
	overflow-x: hidden;

	&.template-home {
		$mountain-url: url(../img/mountains.png);
		$background-white: linear-gradient(transparent 100vh, white 100vh);
		$background-pattern: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15' height='9' viewBox='0 0 20 12'%3E%3Cg fill-rule='evenodd'%3E%3Cg id='charlie-brown' fill='%23121315' fill-opacity='0.4'%3E%3Cpath d='M9.8 12L0 2.2V.8l10 10 10-10v1.4L10.2 12h-.4zm-4 0L0 6.2V4.8L7.2 12H5.8zm8.4 0L20 6.2V4.8L12.8 12h1.4zM9.8 0l.2.2.2-.2h-.4zm-4 0L10 4.2 14.2 0h-1.4L10 2.8 7.2 0H5.8z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
		$background-color: #1f292e;
		// background stack:
		// (1) include the mountains at the bottom of the screen
		// (2) make sure that the background color and pattern is only visible on the first 100vh
		// (3) the hero pattern
		// (4) dark background color
		background: $mountain-url center unquote("max(430px, 35vh)")/100% no-repeat, $background-white,
			$background-color;

		@include breakpoint(constrained-width) {
			background: $mountain-url center 45vh /#{$max-width} no-repeat,
				$background-white, $background-color;
		}
	}

	&.template-digital-minds .hero {
		$img-url: url(../img/digital-minds-bg.jpg);
		$darken-overlay-color: linear-gradient(rgba(23,0,0,0.48), rgba(23,0,0,0.48));
		background: $darken-overlay-color, $img-url 80% 50% / cover no-repeat;
	}

	&.template-blog\/index .hero,
	&.template-blog\/entry .hero {
		$img-url: url(../img/blog-bg.jpg);
		$darken-overlay-color: linear-gradient(rgba(23,0,0,0.6), rgba(23,0,0,0.6));
		background: $darken-overlay-color, $img-url 80% 80% / cover no-repeat;
	}
}

p, li {
	line-height: 145%;
	-webkit-hyphens: auto;
	-ms-hyphens: auto;
	hyphens: auto;
}

a {
	color: inherit;
	text-decoration: none;
}

.horizontal\:center {
	display: table;
	margin-left: auto;
	margin-right: auto;
}

.mobile\:horizontal\:center {
	@include breakpoint(phone-width) {
		display: table;
		margin-left: auto;
		margin-right: auto;
	}
}

.horizontal\:full {
	display: block;
	width: 100%;
}

.float\:left { float: left; }
.float\:right { float: right; }

.vertical\:center {
	display: table;
	margin-top: auto;
	margin-bottom: auto;
}

.avatar\:rounded {
	border-radius: 50%;
	height: 5.5rem;
	width: 5.5rem;
	box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.33);
	background-size: cover;
}

.bg-dark {
	@extend .color\:neutral-100;
}

.bg\:dark-tiled {
	background-image: url("data:image/svg+xml,%3Csvg width='84' height='48' viewBox='0 0 84 48' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 0h12v6H0V0zm28 8h12v6H28V8zm14-8h12v6H42V0zm14 0h12v6H56V0zm0 8h12v6H56V8zM42 8h12v6H42V8zm0 16h12v6H42v-6zm14-8h12v6H56v-6zm14 0h12v6H70v-6zm0-16h12v6H70V0zM28 32h12v6H28v-6zM14 16h12v6H14v-6zM0 24h12v6H0v-6zm0 8h12v6H0v-6zm14 0h12v6H14v-6zm14 8h12v6H28v-6zm-14 0h12v6H14v-6zm28 0h12v6H42v-6zm14-8h12v6H56v-6zm0-8h12v6H56v-6zm14 8h12v6H70v-6zm0 8h12v6H70v-6zM14 24h12v6H14v-6zm14-8h12v6H28v-6zM14 8h12v6H14V8zM0 8h12v6H0V8z' fill='%231c2127' fill-opacity='0.4' fill-rule='evenodd'/%3E%3C/svg%3E");
}

.bg-light {
	@extend .color\:neutral-700;
}
footer {
	display: flex;
	padding: 25px 35px;
	flex-direction: column;
	margin-top: -1px;

	.socialmedia {
		display: flex;
		justify-content: center;

		$hover-color: map-get($colors, primary-400);

		.instagram,
		.linkedin {
			background: white;
			color: map-get($colors, neutral-700);
			border-radius: 100%;
			padding: map-get($spacings, 2);
			line-height: 0;
			transition: background 150ms ease-in-out;

			&:hover { background: $hover-color; }

			svg { height: map-get($spacings, 5); width: map-get($spacings, 5); }
		}

		.facebook {
			color: white;
			transition: color 150ms ease-in-out;
			&:hover { color: $hover-color; }
			svg {
				width: map-get($spacings, 9);
			 	height: map-get($spacings, 9);
			}
		}
	}

	ul {
		display: flex;
		align-self: baseline;
		margin: 0 auto;
		list-style-type: none;

		@include breakpoint(phone-width) {
			flex-direction: column;
			li {
				margin-bottom: map-get($spacings, 4);
				text-decoration: underline;
				text-align: center;
			}
		}

		@include breakpoint(not-phone-width) {
			li {
				&:not(:last-child) {
					margin-right: map-get($spacings, 4);
				}
			}
		}
	}
}

.hero-video {
	@extend .card\:dark, .horizontal\:center;
	padding: 0;
	width: 700px;
	height: 395px;
	position: relative;
	overflow: hidden;
	border: none;

	iframe {
		position: absolute;
		top: -1px;
		left: -1px;
		width: 101%;
		height: 101%;
		border-radius: 12px;
		overflow: hidden;
	}

	@include breakpoint(constrained-width) {
		width: 90vw;
		height: 50vw;
	}
}

.student-saying-card {
	display: flex;
	flex-direction: column;
	margin-bottom: map-get($spacings, 8);
	padding: 0 map-get($spacings, 2);
	align-items: center;

	img {
		margin-right: map-get($spacings, 6);
		height: 70px;
		width: 70px;
		border-radius: 100%;
		border: 1px solid #1E282C;
		flex-shrink: 0;
		background-size: cover;
	}

	.student {
		display: flex;
		align-items: center;

		@include breakpoint(phone-width, -200) {
			flex-direction: column;
			text-align: center;

			img { margin-bottom: map-get($spacings, 6); }
		}

		& > div {
			p {
				margin-top: 0;
			}
			div {
				@extend .text\:sm, .mt\:4;
			}
			margin-bottom: map-get($spacings, 1);
		}
	}
}

.map {
	$map-height: 565px;
	height: $map-height;
	position: relative;

	#vienna-map {
		$scale: 0.75;
		height: 100% / $scale;
		width: 100% / $scale;
		transform: scale($scale);
		position: absolute;
		left: -(100% / $scale - 100) / 2;
		top: -(100% / $scale - 100) / 2;

		pointer-events: none;
		//filter: invert(1) saturate(0.3);
	}

	.contact-card {
		$card-height: 446px;

		position: relative;
		top: ($map-height - $card-height) / 2;

		max-width: 375px;
		height: $card-height;
		display: flex;
		justify-content: flex-end;
		flex-direction: column;

		background-image: url("../img/weX-contact-card.jpg");
		background-size: cover;
		font-style: normal;

		h3 {
			font-size: map-get($font-sizes, base);
			margin-top: map-get($spacings, 3);
			font-weight: 700;
		}

		p {
			margin-top: 0;
		}
	}
}

nav {
	display: flex;
	position: relative;

	justify-content: space-between;

	.logo {
		height: 24px;
		align-self: center;

		img {
			height: 100%;
		}
	}

	.local-links {
		display: flex;
		align-self: baseline;

		li {
			list-style-type: none;
			&:not(:last-child) {
				margin-right: 50px;
			}
		}

		@include breakpoint(constrained-width, 200) {
			display: none;
		}
	}

	.hamburger {
		padding: 9px;
		display: none;

		@include breakpoint(phone-width, -200) {
			display: block;
		}
	}

	.buttons {
		text-align: right;
		align-self: baseline;
		transition: opacity 150ms ease-in-out;
		display: flex;

		@include breakpoint(phone-width, -200) {
			@include card-common();

			position: absolute;
			right: map-get($spacings, 5);
			top: -1000rem;
			opacity: 0;
			width: 10rem;
			background-color: map-get($colors, neutral-500);

			flex-direction: column;

			& > *:not(.to-edges) {
				margin-left: map-get($spacings, 4);
				margin-right: map-get($spacings, 4);
			}
		}
	}

	@include breakpoint(phone-width, -200) {
		.hamburger.is-active + .buttons {
			opacity: 1;
			top: 120%;
		}
	}
}

div.hero,
div.hero\:max {
	&.hero\:max {
		height: 100vh;
	}

	display: flex;
	flex-direction: column;

	.stage {
		margin-top: 15vh;

		@include breakpoint(phone-width) {
			margin-top: 10vh;

			h1 {
				margin-bottom: 7vh;
			}

			.container\:columns > * {
				margin-bottom: map-get($spacings, 4);
			}
		}
	}
}

div.digital-minds {
	@extend .bg\:neutral-700;
	@extend .mt\:32;

	background-image: url("data:image/svg+xml,%3Csvg width='84' height='48' viewBox='0 0 84 48' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 0h12v6H0V0zm28 8h12v6H28V8zm14-8h12v6H42V0zm14 0h12v6H56V0zm0 8h12v6H56V8zM42 8h12v6H42V8zm0 16h12v6H42v-6zm14-8h12v6H56v-6zm14 0h12v6H70v-6zm0-16h12v6H70V0zM28 32h12v6H28v-6zM14 16h12v6H14v-6zM0 24h12v6H0v-6zm0 8h12v6H0v-6zm14 0h12v6H14v-6zm14 8h12v6H28v-6zm-14 0h12v6H14v-6zm28 0h12v6H42v-6zm14-8h12v6H56v-6zm0-8h12v6H56v-6zm14 8h12v6H70v-6zm0 8h12v6H70v-6zM14 24h12v6H14v-6zm14-8h12v6H28v-6zM14 8h12v6H14V8zM0 8h12v6H0V8z' fill='%231c2127' fill-opacity='0.4' fill-rule='evenodd'/%3E%3C/svg%3E");
	padding-top: map-get($spacings, 10);

	.light-bg-break {
		background-image: url("data:image/svg+xml,%3Csvg width='84' height='48' viewBox='0 0 84 48' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 0h12v6H0V0zm28 8h12v6H28V8zm14-8h12v6H42V0zm14 0h12v6H56V0zm0 8h12v6H56V8zM42 8h12v6H42V8zm0 16h12v6H42v-6zm14-8h12v6H56v-6zm14 0h12v6H70v-6zm0-16h12v6H70V0zM28 32h12v6H28v-6zM14 16h12v6H14v-6zM0 24h12v6H0v-6zm0 8h12v6H0v-6zm14 0h12v6H14v-6zm14 8h12v6H28v-6zm-14 0h12v6H14v-6zm28 0h12v6H42v-6zm14-8h12v6H56v-6zm0-8h12v6H56v-6zm14 8h12v6H70v-6zm0 8h12v6H70v-6zM14 24h12v6H14v-6zm14-8h12v6H28v-6zM14 8h12v6H14V8zM0 8h12v6H0V8z' fill='%23e7e8ea' fill-opacity='0.4' fill-rule='evenodd'/%3E%3C/svg%3E");
	}

	.logo {
		@extend .horizontal\:center;
		display: block;
		height: 29px;
	}

	.cwp-graphic {
		position: relative;

		h3 {
			color: map-get($colors, primary-300);
		}

		img.cube {
			width: 288px;
		}

		@include breakpoint(constrained-width, $overhang-lg * 2) {
			.classroom-arc,
			.workshop-arc,
			.project-arc {
				display: none;
			}

			img.cube {
				display: block;
				margin: 0 auto;
			}

			h3 {
				margin-top: map-get($spacings, 6);
			}
		}

		@include breakpoint(unconstrained-width, $overhang-lg * 2) {
			height: 570px;

			img.cube {
				position: absolute;
				top: 40px;
				left: 50%;
				margin-left: -144px;
			}

			.classroom {
				width: 260px;
				position: absolute;
				top: 62px;
				left: 20px;

				h3 {
					text-align: center;
				}
			}

			.workshop {
				width: 260px;
				position: absolute;
				top: 5px;
				right: 0;
			}

			.project {
				width: 320px;
				position: absolute;
				bottom: 0;
				left: 50%;
				margin-left: -118px;
			}

			.classroom-arc {
				position: absolute;
				left: 146px;
				top: 13px;
			}

			.workshop-arc {
				position: absolute;
				right: 270px;
				top: 12px;
			}

			.project-arc {
				position: absolute;
				top: 310px;
				left: 306px;
			}
		}
	}

	.container\:slanted-rotation > div {
		height: 1.5rem;
	}

	.jonas-and-monica {
		position: relative;
		z-index: 1;

		img {
			height: 440px;
			position: absolute;

			&.jonas {
				top: -200px;
				left: -280px;
			}

			&.monica {
				top: -350px;
				right: -320px;
			}

			@include breakpoint(constrained-width, 700) {
				&.jonas {
					left: -10vw;
					top: -300px;
				}

				&.monica {
					right: -13vw;
				}
			}

			@include breakpoint(constrained-width, 2 * $overhang-lg) {
				display: none;
			}
		}
	}

	.buy-row {
		position: relative;
		padding-bottom: map-get($spacings, 24);

		.decoration-images {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			pointer-events: none;

			.heather {
				position: absolute;
				height: 440px;
				bottom: 0;

				@include breakpoint(unconstrained-width) {
					left: 0;
				}
				@include breakpoint(constrained-width) {
					right: 80vw;
				}
			}

			.blue-crystal {
				position: absolute;
				width: 135px;
				bottom: 450px;
				@include breakpoint(unconstrained-width) {
					right: 0;
				}
				@include breakpoint(constrained-width) {
					left: 85vw;
				}
			}

			.suitcase {
				position: absolute;
				width: 130px;
				bottom: -130px;
				-webkit-transform: scaleX(-1);
				transform: scaleX(-1);
				@include breakpoint(unconstrained-width) {
					right: -2px;
				}
				@include breakpoint(constrained-width) {
					left: 78vw;
				}
			}
		}

		.logo {
			height: 18px;
		}

		.card\:light {
			max-width: 315px;
		}

	}
}

ul {
	list-style-type: '–';
	padding-left: map-get($spacings, 2);

	li { padding-left: map-get($spacings, 2); }
}

ul.column-list {
	display: flex;
	flex-wrap: wrap;

		li {
			flex: 1 14rem;
		}
}

ul.check-list {
	list-style: none;

	li {
		position: relative;
		padding-left: 2.4rem;
		padding-top: 0.1rem;
		margin-bottom: 0.7rem;
	}

	li::before {
		content: "";
		display: inline-block;
		height: 1.5rem;
		width: 1.5rem;
		position: absolute;
		top: 0rem;
		left: 0rem;
		background-image: url(http://placehold.it/32?text=fallback); // Fallback PNG
		background-image: url(../img/primary-check-list-icon.svg);
		background-size: contain;
		background-repeat: no-repeat;
		margin-right: 0.5em;
	}
}

.siema-container {
	display: flex;
	align-items: stretch;
	flex-direction: row;

	a.siema-prev,
	a.siema-next {
		background: none;
		border: none;
		outline: none;
		box-shadow: none;
		color: white;
		display: flex;
		align-items: center;

		&:hover {
			cursor: pointer;

		}
	}

	a.siema-prev {
		transform: rotate(180deg);
	}

	.siema {
		margin: 0 map-get($spacings, 4);

		& > div {
			display: flex;
			align-items: center;
		}
	}
}

.siema-page-indicator {
	text-align: center;
	button {
		margin: 0 map-get($spacings, 1);
		border-radius: 100%;
		height: map-get($spacings, 2);
		width: map-get($spacings, 2);
	}
}

.accordion {
	display: flex;
	flex-direction: column;
	border-radius: 4px;
	cursor: pointer;
	padding: 0;

	input { display: none; }
	label {
		padding: map-get($spacings, 4) map-get($spacings, 6);
		display: flex;
		flex-direction: row;
		cursor: pointer;
		font-weight: 700;
	}
	input {
		& + label + div {
			display: none;
			padding: map-get($spacings, 2) map-get($spacings, 6) map-get($spacings, 6);
			margin-top: - map-get($spacings, 4);
		}

		& + label > .handle-open { display: none; margin-left: map-get($spacings, 2); }
		& + label > .handle-closed { display: block; margin-left: map-get($spacings, 2); }
	}
	input:checked {
		& + label + div {
			display: block
		}

		& + label > .handle-open { display: block; }
		& + label > .handle-closed { display: none; }
	}

	.index {
		@extend .text\:sm;
		width: map-get($spacings, 6);
		flex-shrink: 0;
	}

	.question {
		@extend .text\:sm;
		flex: 1;
	}

	img {
		width: 100%;
	}
}

.ninja-accordion {
	display: flex;
	flex-direction: column;
	border-radius: 4px;
	cursor: pointer;
	padding: 0;

	input { display: none; }
	label {
		@extend .text\:lg;
		padding: map-get($spacings, 4) 0;
		display: flex;
		flex-direction: row;
		cursor: pointer;
	}
	input {
		& + label + div {
			display: none;
			padding: map-get($spacings, 2) map-get($spacings, 2) map-get($spacings, 6);
			margin-top: - map-get($spacings, 4);
		}

		& + label > .handle-open { display: none; margin-left: map-get($spacings, 2); }
		& + label > .handle-closed { display: block; margin-left: map-get($spacings, 2); }
	}
	input:checked {
		& + label + div {
			display: block
		}

		& + label > .handle-open { display: block; }
		& + label > .handle-closed { display: none; }
	}

	.index {
		width: map-get($spacings, 6);
		flex-shrink: 0;
	}

	.question {
		flex: 1;
		text-decoration: underline;
	}
}

.banner {
	--height: #{map-get($spacings, 8)};
	display:block;
	z-index: 9998;
	top: calc(100vh - var(--height));
	height: var(--height);
	line-height: var(--height) ;

	@include breakpoint(phone-width) {
		padding-bottom: var(--height);
		top: calc(100vh - 2 * var(--height));
		height: auto;
	}
}
