.sticky {
	position: sticky;
}

@each $spacing, $value in $spacings {
	.top\:#{$spacing} {
		top: $value;
	}
	.bottom\:#{$spacing} {
		bottom: $value;
	}
}

.top\:90vh {
	top: 90vh;
}
