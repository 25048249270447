@mixin card-common() {
	border-radius: 8px;
	box-shadow: 0 15px 46px 0 rgba(0, 0, 0, 0.41);
	overflow: hidden;

	& > *:not(.to-edges) {
		margin-left: map-get($spacings, 6);
		margin-right: map-get($spacings, 6);
	}

	& > .to-edges:first-child + *:not(.to-edges),
	& > *:first-child:not(.to-edges) {
		margin-top: map-get($spacings, 5);
	}

	& > *:last-child:not(.to-edges) {
		margin-bottom: map-get($spacings, 5);
	}

	&.card\:sm {
		& > *:not(.to-edges) {
			margin-left: map-get($spacings, 4);
			margin-right: map-get($spacings, 4);
		}

		& > .to-edges:first-child + *:not(.to-edges),
		& > *:first-child:not(.to-edges) {
			margin-top: map-get($spacings, 3);
		}

		& > *:last-child:not(.to-edges) {
			margin-bottom: map-get($spacings, 3);
		}
	}
}

.card\:dark {
	@include card-common();
	@extend .bg\:neutral-600;
}

.card\:light {
	@include card-common();
	background: #f2f2f3;
	box-shadow: 0 2px 13px 0 rgba(0, 0, 0, 0.22),
		0 15px 46px 0 rgba(0, 0, 0, 0.3);
}

.card\:video {
	@include card-common();
	padding: 0;
	overflow: hidden;

	& > * { margin: 0; }
}

.card\:shadow-light {
	box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.22);
}


.card\:top-image {
	display: block;
	height: 140px;

	@include breakpoint(unconstrained-width) {
		margin: -80px auto 0;
	}

	@include breakpoint(constrained-width) {
		float: left;
		margin-bottom: auto;
		margin-right: map-get($spacings, 5);
	}
}
