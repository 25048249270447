@import "../base/_colors.scss";
%button-shared {
	border-radius: 8px;
	display: inline-block;
	text-align: center;
}

.button\:sm {
	padding: 7px 18px;
	font-weight: 700;
}

.button\:lg {
	padding: 15px 30px;
	font-weight: 800;

	@include breakpoint(phone-width) {
		padding: 11px 18px;
		font-weight: 700;
	}
}

.button\:xl {
	padding: 15px 60px;
	font-weight: 800;
	font-size: 1.3rem;

	@include breakpoint(phone-width) {
		padding: 15px 30px;
		font-weight: 700;
		font-size: 1.2rem;
	}
}

@each $color-name, $color in $colors {
	.color\:#{$color-name} {
		color: $color;
	}

	.button\:#{$color-name} {
		@extend %button-shared;
		background-color: $color;
		transition: background-color 120ms ease-in-out;

		&:hover {
			background-color: lighten($color, 5%);
			cursor: pointer;
		}
	}
}
