$max-width: 1000px;
$overhang: 50px;
$overhang-lg: 75px;
$overhang-xl: 100px;

// Stupid SASS converts hsla to Hex Codes. This conversion seems to be
// lossy, resulting in different HEX Values than the ones Sketch says
// they are.
$colors: (
	primary: #F86754, // hsla(7, 92%, 65%, 1),
	primary-100: #FFE5E5, // hsla(0, 100%, 95%, 1),
	primary-200: #FA918F, // hsla(1, 91%, 77%, 1),
	primary-300: #F86754, // hsla(7, 92%, 65%, 1),
	primary-400: #E44A2F, // hsla(9, 77%, 54%, 1),
	primary-500: #990F0F, // hsla(0, 83%, 38%, 1),
	primary-600: #800000, // hsla(0, 100%, 25%, 1),
	primary-700: #6B0000, // hsla(0, 100%, 15%, 1),
	neutral-50: #F9F9F9, // hsla(240, 4%, 95%, 1),
	neutral-100: #F2F2F3, // hsla(240, 4%, 95%, 1),
	neutral-200: #D7D7D7, // hsla(0, 0%, 84%, 1),
	neutral-300: #B3ACB4, // hsla(292, 5%, 69%, 1),
	neutral-400: #8E8490, // hsla(290, 5%, 54%, 1),
	neutral-500: #69616B, // hsla(288, 5%, 40%, 1),
	neutral-600: #3B4145, // hsla(204, 8%, 25%, 1),
	neutral-700: #1E282C, // hsla(197, 19%, 15%, 1)
	neutral-800: #00121A,
	white: #FFFFFF,
);

$spacings: (
	0: 0,
	1: 0.25rem,
	2: 0.5rem,
	3: 0.75rem,
	4: 1rem,
	5: 1.25rem,
	6: 1.5rem,
	8: 2rem,
	9: 2.25rem,
	10: 2.5rem,
	12: 3rem,
	16: 4rem,
	20: 5rem,
	24: 6rem,
	32: 8rem
);

$font-sizes: (
	2xs: 10px,
	xs: 12px,
	sm: 14px,
	base: 16px,
	lg: 18px,
	xl: 20px,
	2xl: 24px,
	3xl: 30px,
	4xl: 36px,
	5xl: 48px,
);

$font-family-sans: "Open Sans", sans-serif;
