h1 {
	@extend .mt\:0;
	@extend .mb\:4;
	font-weight: 800;

	font-size: map-get($font-sizes, 5xl);
	@include breakpoint(constrained-width) {
		font-size: map-get($font-sizes, 4xl);
	}

	@include breakpoint(phone-width) {
		font-size: map-get($font-sizes, 3xl);
	}
}

.quote\:handwritten + h2 {
	@extend .mt\:5;
}

h2 {
	@extend .mt\:20;
	margin-bottom: map-get($spacings, 4);
	font-weight: 700;

	font-size: map-get($font-sizes, 3xl);
	@include breakpoint(constrained-width) {
		font-size: map-get($font-sizes, 2xl);
	}

	@include breakpoint(phone-width) {
		font-size: map-get($font-sizes, xl);
	}
}

h3 {
	@extend .text\:xl;
	font-weight: 900;

	@include breakpoint(phone-width) {
		font-size: map-get($font-sizes, lg);
	}
}
