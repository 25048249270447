$max-width: 900px;
$overhang: 50px;
$overhang-lg: 75px;
$overhang-xl: 100px;

@mixin breakpoint($point, $addition: 0) {
	@if $point == constrained-width {
		@media (max-width: $max-width + $addition) {
			@content;
		}
	}
	@if $point == unconstrained-width {
		@media (min-width: $max-width + $addition) {
			@content;
		}
	}
	@if $point == phone-width {
		@media (max-width: 765px + $addition) {
			@content;
		}
	}
	@if $point == not-phone-width {
		@media (min-width: 765px + $addition) {
			@content;
		}
	}
}

.hide\:phone {
	@include breakpoint(phone-width) {
		display: none !important;
	}
}

.show\:phone-only {
	display: none !important;
	@include breakpoint(phone-width) {
		display: initial !important;
	}
}

.container\:fluid {
	max-width: $max-width;
	margin-left: auto;
	margin-right: auto;

	padding-left: map-get($spacings, 5);
	padding-right: map-get($spacings, 5);

}

.container\:fluid.container\:narrowed {
	max-width: $max-width - 2 * $overhang;
}

.container\:fluid.container\:narrowed-2 {
	max-width: $max-width - 2 * $overhang-lg;
}

.container\:fluid.container\:narrowed-3 {
	max-width: $max-width - 2 * $overhang-xl;
}

.container\:fluid.container\:narrowed-4 {
	max-width: $max-width - 4 * $overhang-xl;
}

.container\:fluid.container\:overhang {
	max-width: $max-width + 2 * $overhang;
}

.container\:fluid.container\:overhang-lg {
	max-width: $max-width + 2 * $overhang-lg;
}

.container\:fluid.container\:overhang-xl {
	max-width: $max-width + 2 * $overhang-xl;
}

.container\:fluid.container\:overhang-2xl {
	max-width: $max-width + 4 * $overhang-xl;
}

.container\:fluid.container\:overhang-4xl {
	max-width: $max-width + 8 * $overhang-xl;
}

.container\:fluid > .container\:overhang {
	@include breakpoint(unconstrained-width, $overhang * 2) {
		margin-left: -$overhang;
		margin-right: -$overhang;
	}
}

.container\:fluid > .container\:overhang-lg {
	@include breakpoint(unconstrained-width, $overhang-lg * 2) {
		margin-left: -$overhang-lg;
		margin-right: -$overhang-lg;
	}
}

.container\:fluid > .container\:overhang-xl {
	@include breakpoint(unconstrained-width, $overhang-xl * 2) {
		margin-left: -$overhang-xl;
		margin-right: -$overhang-xl;
	}
}

.container\:fluid > .container\:overhang-2xl {
	@include breakpoint(unconstrained-width, $overhang-xl * 2) {
		margin-left: -$overhang-xl * 2;
		margin-right: -$overhang-xl * 2;
	}
}

.container\:fluid > .container\:overhang-4xl {
	@include breakpoint(unconstrained-width, $overhang-xl * 4) {
		margin-left: -$overhang-xl * 4;
		margin-right: -$overhang-xl * 4;
	}
}

.container\:columns {
	display: flex;
	justify-content: center;

	@include breakpoint(unconstrained-width, -100px) {
		&:not(.columns\:nofill) > * {
			flex: 1;
		}
		& > *:not(:last-child) {
			margin-right: map-get($spacings, 12);
		}
		&.columns\:small-hgap > *:not(:last-child) {
			margin-right: map-get($spacings, 6);
		}
	}

	@include breakpoint(constrained-width, -100px) {
		flex-direction: column;
		& > *:not(:last-child) {
			margin-bottom: map-get($spacings, 8);
		}
		&.columns\:small-vgap > *:not(:last-child) {
			margin-bottom: map-get($spacings, 4);
		}
		&.columns\:no-vgap > *:not(:last-child) {
			margin-bottom: 0;
		}
	}

	img {
		max-width: 100%;
	}
}

$slant-height: 6vw;
.container\:slanted {
	margin-top: -$slant-height / 2;
	margin-bottom: -$slant-height / 2;
	padding: ($slant-height * 0.75) 0 $slant-height;
	-webkit-clip-path: polygon(
		0% $slant-height,
		100% 0%,
		100% calc(100% - #{$slant-height}),
		0% 100%
	);
	clip-path: polygon(
		0% $slant-height,
		100% 0%,
		100% calc(100% - #{$slant-height}),
		0% 100%
	);
}

.container\:slanted-rotation {
	$angle: -3.434deg; // arccos( 100 / sqrt(100^2 + $slant-height^2) )
	transform: rotate($angle);
	margin-left: -1vw;
	margin-right: -1vw;
}

.container\:slanted-top {
	margin-top: -$slant-height / 2;
	padding: ($slant-height * 0.75) 0 0;
	-webkit-clip-path: polygon(0% $slant-height, 100% 0%, 100% 100%, 0% 100%);
	clip-path: polygon(0% $slant-height, 100% 0%, 100% 100%, 0% 100%);
}

.container\:slanted + .container\:slanted,
.container\:slanted + .container\:slanted-top {
	margin-top: -$slant-height
}

// .container\:slanted > .container\:slanted {
// 	margin-bottom: -$slant-height;
// 	padding: 4vw 0;
// }

.container\:splits-reverse,
.container\:splits {
	display: flex;
	flex-direction: row;

	.flex\:1 {
		flex: 1;
	}
}
.container\:splits {
	flex-direction: row;
}
.container\:splits-reverse {
	flex-direction: row-reverse;
}

@each $spacing, $value in $spacings {
	.h\:#{$spacing} {
		height: $value;
	}
	.w\:#{$spacing} {
		width: $value;
	}
	.line-h\:#{$spacing} {
		line-height: $value;
	}
}
