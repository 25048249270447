@charset "UTF-8";
.color\:primary {
  color: #F86754;
}

.bg\:primary {
  background-color: #F86754;
}

.color\:primary-100 {
  color: #FFE5E5;
}

.bg\:primary-100, .form-group .status-msg\:error {
  background-color: #FFE5E5;
}

.color\:primary-200 {
  color: #FA918F;
}

.bg\:primary-200 {
  background-color: #FA918F;
}

.color\:primary-300 {
  color: #F86754;
}

.bg\:primary-300 {
  background-color: #F86754;
}

.color\:primary-400 {
  color: #E44A2F;
}

.bg\:primary-400 {
  background-color: #E44A2F;
}

.color\:primary-500, .form-group .status-msg\:error {
  color: #990F0F;
}

.bg\:primary-500 {
  background-color: #990F0F;
}

.color\:primary-600 {
  color: #800000;
}

.bg\:primary-600 {
  background-color: #800000;
}

.color\:primary-700 {
  color: #6B0000;
}

.bg\:primary-700 {
  background-color: #6B0000;
}

.color\:neutral-50 {
  color: #F9F9F9;
}

.bg\:neutral-50 {
  background-color: #F9F9F9;
}

.color\:neutral-100, .bg-dark {
  color: #F2F2F3;
}

.bg\:neutral-100 {
  background-color: #F2F2F3;
}

.color\:neutral-200 {
  color: #D7D7D7;
}

.bg\:neutral-200 {
  background-color: #D7D7D7;
}

.color\:neutral-300 {
  color: #B3ACB4;
}

.bg\:neutral-300 {
  background-color: #B3ACB4;
}

.color\:neutral-400 {
  color: #8E8490;
}

.bg\:neutral-400 {
  background-color: #8E8490;
}

.color\:neutral-500, .text\:old-price {
  color: #69616B;
}

.bg\:neutral-500 {
  background-color: #69616B;
}

.color\:neutral-600 {
  color: #3B4145;
}

.bg\:neutral-600, .card\:dark, .hero-video {
  background-color: #3B4145;
}

.color\:neutral-700, .bg-light {
  color: #1E282C;
}

.bg\:neutral-700, div.digital-minds {
  background-color: #1E282C;
}

.color\:neutral-800 {
  color: #00121A;
}

.bg\:neutral-800 {
  background-color: #00121A;
}

.color\:white {
  color: #FFFFFF;
}

.bg\:white {
  background-color: #FFFFFF;
}

.mt\:0, h1 {
  margin-top: 0;
}

.mb\:0 {
  margin-bottom: 0;
}

.mr\:0 {
  margin-right: 0;
}

.ml\:0 {
  margin-left: 0;
}

.my\:0 {
  margin-top: 0;
  margin-bottom: 0;
}

.pb\:0 {
  padding-bottom: 0;
}

.pt\:0 {
  padding-top: 0;
}

.py\:0 {
  padding-top: 0;
  padding-bottom: 0;
}

.space\:x-0 > *:not(:last-child) {
  margin-right: 0;
}

.mt\:1 {
  margin-top: 0.25rem;
}

.mb\:1 {
  margin-bottom: 0.25rem;
}

.mr\:1 {
  margin-right: 0.25rem;
}

.ml\:1, .input-group label:first-child, .form-group label:first-child {
  margin-left: 0.25rem;
}

.my\:1 {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}

.pb\:1 {
  padding-bottom: 0.25rem;
}

.pt\:1 {
  padding-top: 0.25rem;
}

.py\:1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.space\:x-1 > *:not(:last-child) {
  margin-right: 0.25rem;
}

.mt\:2 {
  margin-top: 0.5rem;
}

.mb\:2, .input-group, .form-group, .bg-dark .form-group {
  margin-bottom: 0.5rem;
}

.mr\:2 {
  margin-right: 0.5rem;
}

.ml\:2 {
  margin-left: 0.5rem;
}

.my\:2 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.pb\:2 {
  padding-bottom: 0.5rem;
}

.pt\:2 {
  padding-top: 0.5rem;
}

.py\:2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.space\:x-2 > *:not(:last-child) {
  margin-right: 0.5rem;
}

.mt\:3, p + p,
h1 + p,
h2 + p,
h3 + p {
  margin-top: 0.75rem;
}

.mb\:3 {
  margin-bottom: 0.75rem;
}

.mr\:3 {
  margin-right: 0.75rem;
}

.ml\:3, .input-group input[type="checkbox"] + label, .form-group input[type="checkbox"] + label {
  margin-left: 0.75rem;
}

.my\:3 {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
}

.pb\:3 {
  padding-bottom: 0.75rem;
}

.pt\:3 {
  padding-top: 0.75rem;
}

.py\:3 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.space\:x-3 > *:not(:last-child) {
  margin-right: 0.75rem;
}

.mt\:4, .student-saying-card .student > div div {
  margin-top: 1rem;
}

.mb\:4, h1 {
  margin-bottom: 1rem;
}

.mr\:4 {
  margin-right: 1rem;
}

.ml\:4 {
  margin-left: 1rem;
}

.my\:4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.pb\:4 {
  padding-bottom: 1rem;
}

.pt\:4 {
  padding-top: 1rem;
}

.py\:4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.space\:x-4 > *:not(:last-child) {
  margin-right: 1rem;
}

.mt\:5, .quote\:handwritten + h2 {
  margin-top: 1.25rem;
}

.mb\:5 {
  margin-bottom: 1.25rem;
}

.mr\:5, .input-group label:first-child, .form-group label:first-child {
  margin-right: 1.25rem;
}

.ml\:5 {
  margin-left: 1.25rem;
}

.my\:5 {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}

.pb\:5 {
  padding-bottom: 1.25rem;
}

.pt\:5 {
  padding-top: 1.25rem;
}

.py\:5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.space\:x-5 > *:not(:last-child) {
  margin-right: 1.25rem;
}

.mt\:6 {
  margin-top: 1.5rem;
}

.mb\:6 {
  margin-bottom: 1.5rem;
}

.mr\:6 {
  margin-right: 1.5rem;
}

.ml\:6 {
  margin-left: 1.5rem;
}

.my\:6 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.pb\:6 {
  padding-bottom: 1.5rem;
}

.pt\:6 {
  padding-top: 1.5rem;
}

.py\:6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.space\:x-6 > *:not(:last-child) {
  margin-right: 1.5rem;
}

.mt\:8 {
  margin-top: 2rem;
}

.mb\:8 {
  margin-bottom: 2rem;
}

.mr\:8 {
  margin-right: 2rem;
}

.ml\:8 {
  margin-left: 2rem;
}

.my\:8 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.pb\:8 {
  padding-bottom: 2rem;
}

.pt\:8 {
  padding-top: 2rem;
}

.py\:8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.space\:x-8 > *:not(:last-child) {
  margin-right: 2rem;
}

.mt\:9 {
  margin-top: 2.25rem;
}

.mb\:9 {
  margin-bottom: 2.25rem;
}

.mr\:9 {
  margin-right: 2.25rem;
}

.ml\:9 {
  margin-left: 2.25rem;
}

.my\:9 {
  margin-top: 2.25rem;
  margin-bottom: 2.25rem;
}

.pb\:9 {
  padding-bottom: 2.25rem;
}

.pt\:9 {
  padding-top: 2.25rem;
}

.py\:9 {
  padding-top: 2.25rem;
  padding-bottom: 2.25rem;
}

.space\:x-9 > *:not(:last-child) {
  margin-right: 2.25rem;
}

.mt\:10, .quote\:handwritten {
  margin-top: 2.5rem;
}

.mb\:10, .quote\:handwritten {
  margin-bottom: 2.5rem;
}

.mr\:10 {
  margin-right: 2.5rem;
}

.ml\:10 {
  margin-left: 2.5rem;
}

.my\:10 {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}

.pb\:10 {
  padding-bottom: 2.5rem;
}

.pt\:10 {
  padding-top: 2.5rem;
}

.py\:10 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.space\:x-10 > *:not(:last-child) {
  margin-right: 2.5rem;
}

.mt\:12 {
  margin-top: 3rem;
}

.mb\:12, .form-section {
  margin-bottom: 3rem;
}

.mr\:12 {
  margin-right: 3rem;
}

.ml\:12 {
  margin-left: 3rem;
}

.my\:12 {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.pb\:12 {
  padding-bottom: 3rem;
}

.pt\:12 {
  padding-top: 3rem;
}

.py\:12 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.space\:x-12 > *:not(:last-child) {
  margin-right: 3rem;
}

.mt\:16 {
  margin-top: 4rem;
}

.mb\:16 {
  margin-bottom: 4rem;
}

.mr\:16 {
  margin-right: 4rem;
}

.ml\:16 {
  margin-left: 4rem;
}

.my\:16 {
  margin-top: 4rem;
  margin-bottom: 4rem;
}

.pb\:16 {
  padding-bottom: 4rem;
}

.pt\:16 {
  padding-top: 4rem;
}

.py\:16 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.space\:x-16 > *:not(:last-child) {
  margin-right: 4rem;
}

.mt\:20, h2 {
  margin-top: 5rem;
}

.mb\:20 {
  margin-bottom: 5rem;
}

.mr\:20 {
  margin-right: 5rem;
}

.ml\:20 {
  margin-left: 5rem;
}

.my\:20 {
  margin-top: 5rem;
  margin-bottom: 5rem;
}

.pb\:20 {
  padding-bottom: 5rem;
}

.pt\:20 {
  padding-top: 5rem;
}

.py\:20 {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.space\:x-20 > *:not(:last-child) {
  margin-right: 5rem;
}

.mt\:24 {
  margin-top: 6rem;
}

.mb\:24 {
  margin-bottom: 6rem;
}

.mr\:24 {
  margin-right: 6rem;
}

.ml\:24 {
  margin-left: 6rem;
}

.my\:24 {
  margin-top: 6rem;
  margin-bottom: 6rem;
}

.pb\:24 {
  padding-bottom: 6rem;
}

.pt\:24 {
  padding-top: 6rem;
}

.py\:24 {
  padding-top: 6rem;
  padding-bottom: 6rem;
}

.space\:x-24 > *:not(:last-child) {
  margin-right: 6rem;
}

.mt\:32, div.digital-minds {
  margin-top: 8rem;
}

.mb\:32 {
  margin-bottom: 8rem;
}

.mr\:32 {
  margin-right: 8rem;
}

.ml\:32 {
  margin-left: 8rem;
}

.my\:32 {
  margin-top: 8rem;
  margin-bottom: 8rem;
}

.pb\:32 {
  padding-bottom: 8rem;
}

.pt\:32 {
  padding-top: 8rem;
}

.py\:32 {
  padding-top: 8rem;
  padding-bottom: 8rem;
}

.space\:x-32 > *:not(:last-child) {
  margin-right: 8rem;
}

@media (max-width: 765px) {
  .hide\:phone {
    display: none !important;
  }
}

.show\:phone-only {
  display: none !important;
}

@media (max-width: 765px) {
  .show\:phone-only {
    display: initial !important;
  }
}

.container\:fluid {
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.container\:fluid.container\:narrowed {
  max-width: 800px;
}

.container\:fluid.container\:narrowed-2 {
  max-width: 750px;
}

.container\:fluid.container\:narrowed-3 {
  max-width: 700px;
}

.container\:fluid.container\:narrowed-4 {
  max-width: 500px;
}

.container\:fluid.container\:overhang {
  max-width: 1000px;
}

.container\:fluid.container\:overhang-lg {
  max-width: 1050px;
}

.container\:fluid.container\:overhang-xl {
  max-width: 1100px;
}

.container\:fluid.container\:overhang-2xl {
  max-width: 1300px;
}

.container\:fluid.container\:overhang-4xl {
  max-width: 1700px;
}

@media (min-width: 1000px) {
  .container\:fluid > .container\:overhang {
    margin-left: -50px;
    margin-right: -50px;
  }
}

@media (min-width: 1050px) {
  .container\:fluid > .container\:overhang-lg {
    margin-left: -75px;
    margin-right: -75px;
  }
}

@media (min-width: 1100px) {
  .container\:fluid > .container\:overhang-xl {
    margin-left: -100px;
    margin-right: -100px;
  }
}

@media (min-width: 1100px) {
  .container\:fluid > .container\:overhang-2xl {
    margin-left: -200px;
    margin-right: -200px;
  }
}

@media (min-width: 1300px) {
  .container\:fluid > .container\:overhang-4xl {
    margin-left: -400px;
    margin-right: -400px;
  }
}

.container\:columns {
  display: flex;
  justify-content: center;
}

@media (min-width: 800px) {
  .container\:columns:not(.columns\:nofill) > * {
    flex: 1;
  }
  .container\:columns > *:not(:last-child) {
    margin-right: 3rem;
  }
  .container\:columns.columns\:small-hgap > *:not(:last-child) {
    margin-right: 1.5rem;
  }
}

@media (max-width: 800px) {
  .container\:columns {
    flex-direction: column;
  }
  .container\:columns > *:not(:last-child) {
    margin-bottom: 2rem;
  }
  .container\:columns.columns\:small-vgap > *:not(:last-child) {
    margin-bottom: 1rem;
  }
  .container\:columns.columns\:no-vgap > *:not(:last-child) {
    margin-bottom: 0;
  }
}

.container\:columns img {
  max-width: 100%;
}

.container\:slanted {
  margin-top: -3vw;
  margin-bottom: -3vw;
  padding: 4.5vw 0 6vw;
  -webkit-clip-path: polygon(0% 6vw, 100% 0%, 100% calc(100% - 6vw), 0% 100%);
  clip-path: polygon(0% 6vw, 100% 0%, 100% calc(100% - 6vw), 0% 100%);
}

.container\:slanted-rotation {
  transform: rotate(-3.434deg);
  margin-left: -1vw;
  margin-right: -1vw;
}

.container\:slanted-top {
  margin-top: -3vw;
  padding: 4.5vw 0 0;
  -webkit-clip-path: polygon(0% 6vw, 100% 0%, 100% 100%, 0% 100%);
  clip-path: polygon(0% 6vw, 100% 0%, 100% 100%, 0% 100%);
}

.container\:slanted + .container\:slanted,
.container\:slanted + .container\:slanted-top {
  margin-top: -6vw;
}

.container\:splits-reverse,
.container\:splits {
  display: flex;
  flex-direction: row;
}

.container\:splits-reverse .flex\:1,
.container\:splits .flex\:1 {
  flex: 1;
}

.container\:splits {
  flex-direction: row;
}

.container\:splits-reverse {
  flex-direction: row-reverse;
}

.h\:0 {
  height: 0;
}

.w\:0 {
  width: 0;
}

.line-h\:0 {
  line-height: 0;
}

.h\:1 {
  height: 0.25rem;
}

.w\:1 {
  width: 0.25rem;
}

.line-h\:1 {
  line-height: 0.25rem;
}

.h\:2 {
  height: 0.5rem;
}

.w\:2 {
  width: 0.5rem;
}

.line-h\:2 {
  line-height: 0.5rem;
}

.h\:3 {
  height: 0.75rem;
}

.w\:3 {
  width: 0.75rem;
}

.line-h\:3 {
  line-height: 0.75rem;
}

.h\:4 {
  height: 1rem;
}

.w\:4 {
  width: 1rem;
}

.line-h\:4 {
  line-height: 1rem;
}

.h\:5 {
  height: 1.25rem;
}

.w\:5 {
  width: 1.25rem;
}

.line-h\:5 {
  line-height: 1.25rem;
}

.h\:6 {
  height: 1.5rem;
}

.w\:6 {
  width: 1.5rem;
}

.line-h\:6 {
  line-height: 1.5rem;
}

.h\:8 {
  height: 2rem;
}

.w\:8 {
  width: 2rem;
}

.line-h\:8 {
  line-height: 2rem;
}

.h\:9 {
  height: 2.25rem;
}

.w\:9 {
  width: 2.25rem;
}

.line-h\:9 {
  line-height: 2.25rem;
}

.h\:10 {
  height: 2.5rem;
}

.w\:10 {
  width: 2.5rem;
}

.line-h\:10 {
  line-height: 2.5rem;
}

.h\:12 {
  height: 3rem;
}

.w\:12 {
  width: 3rem;
}

.line-h\:12 {
  line-height: 3rem;
}

.h\:16 {
  height: 4rem;
}

.w\:16 {
  width: 4rem;
}

.line-h\:16 {
  line-height: 4rem;
}

.h\:20 {
  height: 5rem;
}

.w\:20 {
  width: 5rem;
}

.line-h\:20 {
  line-height: 5rem;
}

.h\:24 {
  height: 6rem;
}

.w\:24 {
  width: 6rem;
}

.line-h\:24 {
  line-height: 6rem;
}

.h\:32 {
  height: 8rem;
}

.w\:32 {
  width: 8rem;
}

.line-h\:32 {
  line-height: 8rem;
}

.text\:2xs, .text\:old-price .currency, .input-group label:first-child, .form-group label:first-child {
  font-size: 10px;
}

.text\:xs, .input-group input[type="checkbox"] + label, .form-group input[type="checkbox"] + label, .form-group .status-msg\:error {
  font-size: 12px;
}

.text\:sm, .student-saying-card .student > div div, .accordion .index, .accordion .question {
  font-size: 14px;
}

.text\:base, body {
  font-size: 16px;
}

.text\:lg, .text\:new-price .currency, .text\:old-price, .ninja-accordion label {
  font-size: 18px;
}

.text\:xl, h3 {
  font-size: 20px;
}

.text\:2xl, .quote\:handwritten {
  font-size: 24px;
}

.text\:3xl, .text\:new-price {
  font-size: 30px;
}

.text\:4xl {
  font-size: 36px;
}

.text\:5xl {
  font-size: 48px;
}

.text\:nowrap {
  white-space: nowrap;
}

.text\:left, .form-group .status-msg\:error {
  text-align: left;
}

.text\:center, .quote\:handwritten {
  text-align: center;
}

.text\:right, .input-group label:first-child, .form-group label:first-child {
  text-align: right;
}

.text\:regular {
  font-weight: normal;
}

.text\:bold, .text\:new-price {
  font-weight: bold;
}

.text\:extra-bold, .input-group label:first-child, .form-group label:first-child {
  font-weight: 900;
}

.text\:italic {
  font-style: italic;
}

.text\:uppercase {
  text-transform: uppercase;
}

.text\:strike-through {
  text-decoration: line-through double;
}

.text\:new-price .currency {
  position: relative;
  top: -10px;
  left: -2px;
}

.text\:old-price {
  position: relative;
}

.text\:old-price:before {
  position: absolute;
  content: "";
  left: -6px;
  top: 50%;
  right: -10px;
  border-top: 1px solid;
  border-color: inherit;
  /* -webkit-transform: rotate(-5deg); */
  -moz-transform: rotate(-18deg);
  -ms-transform: rotate(-18deg);
  -o-transform: rotate(-18deg);
  transform: rotate(-18deg);
}

.text\:old-price .currency {
  position: relative;
  top: -5px;
  left: -2px;
}

.sticky {
  position: sticky;
}

.top\:0 {
  top: 0;
}

.bottom\:0 {
  bottom: 0;
}

.top\:1 {
  top: 0.25rem;
}

.bottom\:1 {
  bottom: 0.25rem;
}

.top\:2 {
  top: 0.5rem;
}

.bottom\:2 {
  bottom: 0.5rem;
}

.top\:3 {
  top: 0.75rem;
}

.bottom\:3 {
  bottom: 0.75rem;
}

.top\:4 {
  top: 1rem;
}

.bottom\:4 {
  bottom: 1rem;
}

.top\:5 {
  top: 1.25rem;
}

.bottom\:5 {
  bottom: 1.25rem;
}

.top\:6 {
  top: 1.5rem;
}

.bottom\:6 {
  bottom: 1.5rem;
}

.top\:8 {
  top: 2rem;
}

.bottom\:8 {
  bottom: 2rem;
}

.top\:9 {
  top: 2.25rem;
}

.bottom\:9 {
  bottom: 2.25rem;
}

.top\:10 {
  top: 2.5rem;
}

.bottom\:10 {
  bottom: 2.5rem;
}

.top\:12 {
  top: 3rem;
}

.bottom\:12 {
  bottom: 3rem;
}

.top\:16 {
  top: 4rem;
}

.bottom\:16 {
  bottom: 4rem;
}

.top\:20 {
  top: 5rem;
}

.bottom\:20 {
  bottom: 5rem;
}

.top\:24 {
  top: 6rem;
}

.bottom\:24 {
  bottom: 6rem;
}

.top\:32 {
  top: 8rem;
}

.bottom\:32 {
  bottom: 8rem;
}

.top\:90vh {
  top: 90vh;
}

.color\:primary {
  color: #F86754;
}

.bg\:primary {
  background-color: #F86754;
}

.color\:primary-100 {
  color: #FFE5E5;
}

.bg\:primary-100, .form-group .status-msg\:error {
  background-color: #FFE5E5;
}

.color\:primary-200 {
  color: #FA918F;
}

.bg\:primary-200 {
  background-color: #FA918F;
}

.color\:primary-300 {
  color: #F86754;
}

.bg\:primary-300 {
  background-color: #F86754;
}

.color\:primary-400 {
  color: #E44A2F;
}

.bg\:primary-400 {
  background-color: #E44A2F;
}

.color\:primary-500, .form-group .status-msg\:error {
  color: #990F0F;
}

.bg\:primary-500 {
  background-color: #990F0F;
}

.color\:primary-600 {
  color: #800000;
}

.bg\:primary-600 {
  background-color: #800000;
}

.color\:primary-700 {
  color: #6B0000;
}

.bg\:primary-700 {
  background-color: #6B0000;
}

.color\:neutral-50 {
  color: #F9F9F9;
}

.bg\:neutral-50 {
  background-color: #F9F9F9;
}

.color\:neutral-100, .bg-dark {
  color: #F2F2F3;
}

.bg\:neutral-100 {
  background-color: #F2F2F3;
}

.color\:neutral-200 {
  color: #D7D7D7;
}

.bg\:neutral-200 {
  background-color: #D7D7D7;
}

.color\:neutral-300 {
  color: #B3ACB4;
}

.bg\:neutral-300 {
  background-color: #B3ACB4;
}

.color\:neutral-400 {
  color: #8E8490;
}

.bg\:neutral-400 {
  background-color: #8E8490;
}

.color\:neutral-500, .text\:old-price {
  color: #69616B;
}

.bg\:neutral-500 {
  background-color: #69616B;
}

.color\:neutral-600 {
  color: #3B4145;
}

.bg\:neutral-600, .card\:dark, .hero-video {
  background-color: #3B4145;
}

.color\:neutral-700, .bg-light {
  color: #1E282C;
}

.bg\:neutral-700, div.digital-minds {
  background-color: #1E282C;
}

.color\:neutral-800 {
  color: #00121A;
}

.bg\:neutral-800 {
  background-color: #00121A;
}

.color\:white {
  color: #FFFFFF;
}

.bg\:white {
  background-color: #FFFFFF;
}

.button\:primary, .button\:primary-100, .button\:primary-200, .button\:primary-300, .button\:primary-400, .button\:primary-500, .button\:primary-600, .button\:primary-700, .button\:neutral-50, .button\:neutral-100, .button\:neutral-200, .button\:neutral-300, .button\:neutral-400, .button\:neutral-500, .button\:neutral-600, .button\:neutral-700, .button\:neutral-800, .button\:white {
  border-radius: 8px;
  display: inline-block;
  text-align: center;
}

.button\:sm {
  padding: 7px 18px;
  font-weight: 700;
}

.button\:lg {
  padding: 15px 30px;
  font-weight: 800;
}

@media (max-width: 765px) {
  .button\:lg {
    padding: 11px 18px;
    font-weight: 700;
  }
}

.button\:xl {
  padding: 15px 60px;
  font-weight: 800;
  font-size: 1.3rem;
}

@media (max-width: 765px) {
  .button\:xl {
    padding: 15px 30px;
    font-weight: 700;
    font-size: 1.2rem;
  }
}

.color\:primary {
  color: #F86754;
}

.button\:primary {
  background-color: #F86754;
  transition: background-color 120ms ease-in-out;
}

.button\:primary:hover {
  background-color: #f97d6c;
  cursor: pointer;
}

.color\:primary-100 {
  color: #FFE5E5;
}

.button\:primary-100 {
  background-color: #FFE5E5;
  transition: background-color 120ms ease-in-out;
}

.button\:primary-100:hover {
  background-color: white;
  cursor: pointer;
}

.color\:primary-200 {
  color: #FA918F;
}

.button\:primary-200 {
  background-color: #FA918F;
  transition: background-color 120ms ease-in-out;
}

.button\:primary-200:hover {
  background-color: #fba9a7;
  cursor: pointer;
}

.color\:primary-300 {
  color: #F86754;
}

.button\:primary-300 {
  background-color: #F86754;
  transition: background-color 120ms ease-in-out;
}

.button\:primary-300:hover {
  background-color: #f97d6c;
  cursor: pointer;
}

.color\:primary-400 {
  color: #E44A2F;
}

.button\:primary-400 {
  background-color: #E44A2F;
  transition: background-color 120ms ease-in-out;
}

.button\:primary-400:hover {
  background-color: #e75e46;
  cursor: pointer;
}

.color\:primary-500, .form-group .status-msg\:error {
  color: #990F0F;
}

.button\:primary-500 {
  background-color: #990F0F;
  transition: background-color 120ms ease-in-out;
}

.button\:primary-500:hover {
  background-color: #b01111;
  cursor: pointer;
}

.color\:primary-600 {
  color: #800000;
}

.button\:primary-600 {
  background-color: #800000;
  transition: background-color 120ms ease-in-out;
}

.button\:primary-600:hover {
  background-color: #9a0000;
  cursor: pointer;
}

.color\:primary-700 {
  color: #6B0000;
}

.button\:primary-700 {
  background-color: #6B0000;
  transition: background-color 120ms ease-in-out;
}

.button\:primary-700:hover {
  background-color: #850000;
  cursor: pointer;
}

.color\:neutral-50 {
  color: #F9F9F9;
}

.button\:neutral-50 {
  background-color: #F9F9F9;
  transition: background-color 120ms ease-in-out;
}

.button\:neutral-50:hover {
  background-color: white;
  cursor: pointer;
}

.color\:neutral-100, .bg-dark {
  color: #F2F2F3;
}

.button\:neutral-100 {
  background-color: #F2F2F3;
  transition: background-color 120ms ease-in-out;
}

.button\:neutral-100:hover {
  background-color: white;
  cursor: pointer;
}

.color\:neutral-200 {
  color: #D7D7D7;
}

.button\:neutral-200 {
  background-color: #D7D7D7;
  transition: background-color 120ms ease-in-out;
}

.button\:neutral-200:hover {
  background-color: #e4e4e4;
  cursor: pointer;
}

.color\:neutral-300 {
  color: #B3ACB4;
}

.button\:neutral-300 {
  background-color: #B3ACB4;
  transition: background-color 120ms ease-in-out;
}

.button\:neutral-300:hover {
  background-color: #bfb9c0;
  cursor: pointer;
}

.color\:neutral-400 {
  color: #8E8490;
}

.button\:neutral-400 {
  background-color: #8E8490;
  transition: background-color 120ms ease-in-out;
}

.button\:neutral-400:hover {
  background-color: #9a919c;
  cursor: pointer;
}

.color\:neutral-500, .text\:old-price {
  color: #69616B;
}

.button\:neutral-500 {
  background-color: #69616B;
  transition: background-color 120ms ease-in-out;
}

.button\:neutral-500:hover {
  background-color: #766d78;
  cursor: pointer;
}

.color\:neutral-600 {
  color: #3B4145;
}

.button\:neutral-600 {
  background-color: #3B4145;
  transition: background-color 120ms ease-in-out;
}

.button\:neutral-600:hover {
  background-color: #474e53;
  cursor: pointer;
}

.color\:neutral-700, .bg-light {
  color: #1E282C;
}

.button\:neutral-700 {
  background-color: #1E282C;
  transition: background-color 120ms ease-in-out;
}

.button\:neutral-700:hover {
  background-color: #28363b;
  cursor: pointer;
}

.color\:neutral-800 {
  color: #00121A;
}

.button\:neutral-800 {
  background-color: #00121A;
  transition: background-color 120ms ease-in-out;
}

.button\:neutral-800:hover {
  background-color: #002434;
  cursor: pointer;
}

.color\:white {
  color: #FFFFFF;
}

.button\:white {
  background-color: #FFFFFF;
  transition: background-color 120ms ease-in-out;
}

.button\:white:hover {
  background-color: white;
  cursor: pointer;
}

.card\:dark, .hero-video {
  border-radius: 8px;
  box-shadow: 0 15px 46px 0 rgba(0, 0, 0, 0.41);
  overflow: hidden;
}

.card\:dark > *:not(.to-edges), .hero-video > *:not(.to-edges) {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

.card\:dark > .to-edges:first-child + *:not(.to-edges), .hero-video > .to-edges:first-child + *:not(.to-edges),
.card\:dark > *:first-child:not(.to-edges),
.hero-video > *:first-child:not(.to-edges) {
  margin-top: 1.25rem;
}

.card\:dark > *:last-child:not(.to-edges), .hero-video > *:last-child:not(.to-edges) {
  margin-bottom: 1.25rem;
}

.card\:dark.card\:sm > *:not(.to-edges), .card\:sm.hero-video > *:not(.to-edges) {
  margin-left: 1rem;
  margin-right: 1rem;
}

.card\:dark.card\:sm > .to-edges:first-child + *:not(.to-edges), .card\:sm.hero-video > .to-edges:first-child + *:not(.to-edges),
.card\:dark.card\:sm > *:first-child:not(.to-edges),
.card\:sm.hero-video > *:first-child:not(.to-edges) {
  margin-top: 0.75rem;
}

.card\:dark.card\:sm > *:last-child:not(.to-edges), .card\:sm.hero-video > *:last-child:not(.to-edges) {
  margin-bottom: 0.75rem;
}

.card\:light {
  border-radius: 8px;
  box-shadow: 0 15px 46px 0 rgba(0, 0, 0, 0.41);
  overflow: hidden;
  background: #f2f2f3;
  box-shadow: 0 2px 13px 0 rgba(0, 0, 0, 0.22), 0 15px 46px 0 rgba(0, 0, 0, 0.3);
}

.card\:light > *:not(.to-edges) {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

.card\:light > .to-edges:first-child + *:not(.to-edges),
.card\:light > *:first-child:not(.to-edges) {
  margin-top: 1.25rem;
}

.card\:light > *:last-child:not(.to-edges) {
  margin-bottom: 1.25rem;
}

.card\:light.card\:sm > *:not(.to-edges) {
  margin-left: 1rem;
  margin-right: 1rem;
}

.card\:light.card\:sm > .to-edges:first-child + *:not(.to-edges),
.card\:light.card\:sm > *:first-child:not(.to-edges) {
  margin-top: 0.75rem;
}

.card\:light.card\:sm > *:last-child:not(.to-edges) {
  margin-bottom: 0.75rem;
}

.card\:video {
  border-radius: 8px;
  box-shadow: 0 15px 46px 0 rgba(0, 0, 0, 0.41);
  overflow: hidden;
  padding: 0;
  overflow: hidden;
}

.card\:video > *:not(.to-edges) {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

.card\:video > .to-edges:first-child + *:not(.to-edges),
.card\:video > *:first-child:not(.to-edges) {
  margin-top: 1.25rem;
}

.card\:video > *:last-child:not(.to-edges) {
  margin-bottom: 1.25rem;
}

.card\:video.card\:sm > *:not(.to-edges) {
  margin-left: 1rem;
  margin-right: 1rem;
}

.card\:video.card\:sm > .to-edges:first-child + *:not(.to-edges),
.card\:video.card\:sm > *:first-child:not(.to-edges) {
  margin-top: 0.75rem;
}

.card\:video.card\:sm > *:last-child:not(.to-edges) {
  margin-bottom: 0.75rem;
}

.card\:video > * {
  margin: 0;
}

.card\:shadow-light {
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.22);
}

.card\:top-image {
  display: block;
  height: 140px;
}

@media (min-width: 1000px) {
  .card\:top-image {
    margin: -80px auto 0;
  }
}

@media (max-width: 1000px) {
  .card\:top-image {
    float: left;
    margin-bottom: auto;
    margin-right: 1.25rem;
  }
}

form.form\:narrow .input-group {
  width: 100%;
}

.input-group, .form-group {
  display: flex;
  align-items: center;
  transition: all 100ms ease-in-out;
}

.input-group label:first-child, .form-group label:first-child {
  text-transform: uppercase;
  letter-spacing: 0.01rem;
  width: 75px;
  flex-shrink: 0;
}

.input-group a, .form-group a {
  text-decoration: underline;
}

.input-group input[type="checkbox"], .form-group input[type="checkbox"] {
  flex: none;
  align-self: first baseline;
  margin-left: 0.75rem;
}

.input-group input[type="checkbox"] + label, .form-group input[type="checkbox"] + label {
  text-align: left;
  line-height: 1.25rem;
  padding: 2px;
}

.input-group input, .form-group input {
  flex: 1;
  background: none;
  border-color: transparent;
  position: relative;
  outline: none;
  height: 1.5rem;
  line-height: 1.5rem;
}

.input-group input[type="password"], .form-group input[type="password"] {
  font-family: Verdana;
}

.input-group input[type="password"]::-webkit-input-placeholder, .form-group input[type="password"]::-webkit-input-placeholder {
  font-family: Verdana;
}

.input-group input[type="password"]:-moz-placeholder, .form-group input[type="password"]:-moz-placeholder {
  font-family: Verdana;
}

.input-group input[type="password"]::-moz-placeholder, .form-group input[type="password"]::-moz-placeholder {
  font-family: Verdana;
}

.input-group input[type="password"]:-ms-input-placeholder, .form-group input[type="password"]:-ms-input-placeholder {
  font-family: Verdana;
}

.input-group input.hide-placeholder::-webkit-input-placeholder, .form-group input.hide-placeholder::-webkit-input-placeholder {
  opacity: 0;
}

.input-group input.hide-placeholder:-moz-placeholder, .form-group input.hide-placeholder:-moz-placeholder {
  opacity: 0;
}

.input-group input.hide-placeholder::-moz-placeholder, .form-group input.hide-placeholder::-moz-placeholder {
  opacity: 0;
}

.input-group input.hide-placeholder:-ms-input-placeholder, .form-group input.hide-placeholder:-ms-input-placeholder {
  opacity: 0;
}

.input-group input::-webkit-input-placeholder, .form-group input::-webkit-input-placeholder {
  opacity: 1;
  font-size: 80%;
  -moz-transition: all 500ms ease-in-out;
  transition: all 500ms ease-in-out;
}

.input-group input:-moz-placeholder, .form-group input:-moz-placeholder {
  opacity: 1;
  font-size: 80%;
  -moz-transition: all 500ms ease-in-out;
  transition: all 500ms ease-in-out;
}

.input-group input::-moz-placeholder, .form-group input::-moz-placeholder {
  opacity: 1;
  font-size: 80%;
  -moz-transition: all 500ms ease-in-out;
  transition: all 500ms ease-in-out;
}

.input-group input:-ms-input-placeholder, .form-group input:-ms-input-placeholder {
  opacity: 1;
  font-size: 80%;
  -moz-transition: all 500ms ease-in-out;
  transition: all 500ms ease-in-out;
}

.input-group {
  padding: 0.5rem 0.75rem;
  border-radius: 4px;
}

.input-group.StripeElement {
  padding: 0.75rem 0.5rem 0.75rem 1rem;
}

.input-group.StripeElement > * {
  flex: 1;
}

.form-group {
  padding: 0;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
}

.form-group .status-msg\:error {
  padding: 0.75rem 1rem;
  line-height: 1.25rem;
  border-radius: 4px 4px 0 0;
  border: solid 1px transparent;
  opacity: 1;
  display: none;
  box-sizing: content-box;
  height: 0px;
  overflow: hidden;
  transition: all 0.3s ease-out;
}

.form-group .status-msg\:error h4, .form-group .status-msg\:error p {
  display: inline;
}

.form-group .hidden.status-msg\:error {
  opacity: 0;
  padding: 0 1rem;
}

.form-group .hidden.status-msg\:error:first-of-type + .input-group {
  border-radius: 4px 4px 0 0;
}

.form-group .status-msg\:error {
  border-color: #E44A2F;
}

.form-group .input-group {
  margin-bottom: 0;
  box-shadow: none;
  border-radius: 0;
  position: relative;
}

.form-group :not(.status-msg\:error) + .input-group:not(:first-of-type):before {
  content: " ";
  top: 0;
  left: 110px;
  right: 0;
  border-top-width: 1px;
  border-top-style: solid;
  position: absolute;
  transition: all 100ms ease-in-out;
}

.form-group .input-group-separator\:100.input-group:not(:first-of-type):before {
  left: 0;
}

.form-group .input-group:first-child {
  border-radius: 4px 4px 0 0;
}

.form-group .input-group:last-child {
  border-radius: 0 0 4px 4px;
}

.input-group {
  background-color: #F2F2F3;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
}

.input-group label {
  color: #69616B;
}

.input-group input {
  color: #3B4145;
}

.input-group input::-webkit-input-placeholder {
  color: #8E8490;
}

.input-group input:-moz-placeholder {
  color: #8E8490;
}

.input-group input::-moz-placeholder {
  color: #8E8490;
}

.input-group input:-ms-input-placeholder {
  color: #8E8490;
}

.input-group:focus-within, .input-group.StripeElement--focus {
  background-color: #D7D7D7;
  color: #1E282C;
}

.input-group:focus-within label, .input-group.StripeElement--focus label {
  color: #3B4145;
}

.input-group:focus-within + .input-group:before, .input-group.StripeElement--focus + .input-group:before {
  border-top-color: #F2F2F3 !important;
}

.form-group {
  background-color: #F2F2F3;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
}

.form-group .input-group:not(:first-child):before {
  border-top-color: #D7D7D7;
}

.form-group .input-group {
  box-shadow: none;
}

.bg-dark .input-group {
  background-color: #1E282C;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
}

.bg-dark .input-group label {
  color: #B3ACB4;
}

.bg-dark .input-group input {
  color: #B3ACB4;
}

.bg-dark .input-group input::-webkit-input-placeholder {
  color: #69616B;
}

.bg-dark .input-group input:-moz-placeholder {
  color: #69616B;
}

.bg-dark .input-group input::-moz-placeholder {
  color: #69616B;
}

.bg-dark .input-group input:-ms-input-placeholder {
  color: #69616B;
}

.bg-dark .input-group:focus-within, .bg-dark .input-group.StripeElement--focus {
  background-color: #3B4145;
  color: #1E282C;
}

.bg-dark .input-group:focus-within label, .bg-dark .input-group.StripeElement--focus label {
  color: #D7D7D7;
}

.bg-dark .input-group:focus-within + .input-group:before, .bg-dark .input-group.StripeElement--focus + .input-group:before {
  border-top-color: #1E282C !important;
}

.bg-dark .form-group {
  background-color: #1E282C;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
}

.bg-dark .form-group .input-group:not(:first-child):before {
  border-top-color: #3B4145;
}

.bg-dark .form-group .input-group {
  box-shadow: none;
}

button {
  border: none;
  height: 39px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.4);
  border-radius: 4px !important;
  letter-spacing: -0.4px;
  color: white;
}

.quote\:handwritten {
  font-family: Calligraffitti;
  -webkit-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
}

.color\:primary {
  color: #F86754;
}

.bg\:primary {
  background-color: #F86754;
}

.color\:primary-100 {
  color: #FFE5E5;
}

.bg\:primary-100, .form-group .status-msg\:error {
  background-color: #FFE5E5;
}

.color\:primary-200 {
  color: #FA918F;
}

.bg\:primary-200 {
  background-color: #FA918F;
}

.color\:primary-300 {
  color: #F86754;
}

.bg\:primary-300 {
  background-color: #F86754;
}

.color\:primary-400 {
  color: #E44A2F;
}

.bg\:primary-400 {
  background-color: #E44A2F;
}

.color\:primary-500, .form-group .status-msg\:error {
  color: #990F0F;
}

.bg\:primary-500 {
  background-color: #990F0F;
}

.color\:primary-600 {
  color: #800000;
}

.bg\:primary-600 {
  background-color: #800000;
}

.color\:primary-700 {
  color: #6B0000;
}

.bg\:primary-700 {
  background-color: #6B0000;
}

.color\:neutral-50 {
  color: #F9F9F9;
}

.bg\:neutral-50 {
  background-color: #F9F9F9;
}

.color\:neutral-100, .bg-dark {
  color: #F2F2F3;
}

.bg\:neutral-100 {
  background-color: #F2F2F3;
}

.color\:neutral-200 {
  color: #D7D7D7;
}

.bg\:neutral-200 {
  background-color: #D7D7D7;
}

.color\:neutral-300 {
  color: #B3ACB4;
}

.bg\:neutral-300 {
  background-color: #B3ACB4;
}

.color\:neutral-400 {
  color: #8E8490;
}

.bg\:neutral-400 {
  background-color: #8E8490;
}

.color\:neutral-500, .text\:old-price {
  color: #69616B;
}

.bg\:neutral-500 {
  background-color: #69616B;
}

.color\:neutral-600 {
  color: #3B4145;
}

.bg\:neutral-600, .card\:dark, .hero-video {
  background-color: #3B4145;
}

.color\:neutral-700, .bg-light {
  color: #1E282C;
}

.bg\:neutral-700, div.digital-minds {
  background-color: #1E282C;
}

.color\:neutral-800 {
  color: #00121A;
}

.bg\:neutral-800 {
  background-color: #00121A;
}

.color\:white {
  color: #FFFFFF;
}

.bg\:white {
  background-color: #FFFFFF;
}

/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */
.hamburger {
  padding: 15px 15px;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  border: 0;
  margin: 0;
  overflow: visible;
}

.hamburger:hover {
  opacity: 0.7;
}

.hamburger.is-active:hover {
  opacity: 0.7;
}

.hamburger.is-active .hamburger-inner,
.hamburger.is-active .hamburger-inner::before,
.hamburger.is-active .hamburger-inner::after {
  background-color: white;
}

.hamburger-box {
  width: 30px;
  height: 24px;
  display: inline-block;
  position: relative;
}

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -2px;
}

.hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
  width: 30px;
  height: 3px;
  background-color: white;
  border-radius: 4px;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;
}

.hamburger-inner::before, .hamburger-inner::after {
  content: "";
  display: block;
}

.hamburger-inner::before {
  top: -10px;
}

.hamburger-inner::after {
  bottom: -10px;
}

.hamburger--spin .hamburger-inner {
  transition-duration: 0.22s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--spin .hamburger-inner::before {
  transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in;
}

.hamburger--spin .hamburger-inner::after {
  transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--spin.is-active .hamburger-inner {
  transform: rotate(225deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger--spin.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0;
  transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out;
}

.hamburger--spin.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(-90deg);
  transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.spinner {
  width: 70px;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.spinner ~ * {
  opacity: 0;
}

.spinner > div {
  width: 18px;
  height: 18px;
  background-color: #fff;
  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

h1 {
  font-weight: 800;
  font-size: 48px;
}

@media (max-width: 1000px) {
  h1 {
    font-size: 36px;
  }
}

@media (max-width: 765px) {
  h1 {
    font-size: 30px;
  }
}

h2 {
  margin-bottom: 1rem;
  font-weight: 700;
  font-size: 30px;
}

@media (max-width: 1000px) {
  h2 {
    font-size: 24px;
  }
}

@media (max-width: 765px) {
  h2 {
    font-size: 20px;
  }
}

h3 {
  font-weight: 900;
}

@media (max-width: 765px) {
  h3 {
    font-size: 18px;
  }
}

* {
  margin: 0;
  padding: 0;
  letter-spacing: -0.01rem;
  box-sizing: border-box;
}

body {
  font-family: "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  overflow-x: hidden;
}

body.template-home {
  background: url(../img/mountains.png) center max(430px, 35vh)/100% no-repeat, linear-gradient(transparent 100vh, white 100vh), #1f292e;
}

@media (max-width: 1000px) {
  body.template-home {
    background: url(../img/mountains.png) center 45vh /1000px no-repeat, linear-gradient(transparent 100vh, white 100vh), #1f292e;
  }
}

body.template-digital-minds .hero {
  background: linear-gradient(rgba(23, 0, 0, 0.48), rgba(23, 0, 0, 0.48)), url(../img/digital-minds-bg.jpg) 80% 50%/cover no-repeat;
}

body.template-blog\/index .hero,
body.template-blog\/entry .hero {
  background: linear-gradient(rgba(23, 0, 0, 0.6), rgba(23, 0, 0, 0.6)), url(../img/blog-bg.jpg) 80% 80%/cover no-repeat;
}

p, li {
  line-height: 145%;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}

a {
  color: inherit;
  text-decoration: none;
}

.horizontal\:center, .hero-video, div.digital-minds .logo {
  display: table;
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 765px) {
  .mobile\:horizontal\:center {
    display: table;
    margin-left: auto;
    margin-right: auto;
  }
}

.horizontal\:full {
  display: block;
  width: 100%;
}

.float\:left {
  float: left;
}

.float\:right {
  float: right;
}

.vertical\:center {
  display: table;
  margin-top: auto;
  margin-bottom: auto;
}

.avatar\:rounded {
  border-radius: 50%;
  height: 5.5rem;
  width: 5.5rem;
  box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.33);
  background-size: cover;
}

.bg\:dark-tiled {
  background-image: url("data:image/svg+xml,%3Csvg width='84' height='48' viewBox='0 0 84 48' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 0h12v6H0V0zm28 8h12v6H28V8zm14-8h12v6H42V0zm14 0h12v6H56V0zm0 8h12v6H56V8zM42 8h12v6H42V8zm0 16h12v6H42v-6zm14-8h12v6H56v-6zm14 0h12v6H70v-6zm0-16h12v6H70V0zM28 32h12v6H28v-6zM14 16h12v6H14v-6zM0 24h12v6H0v-6zm0 8h12v6H0v-6zm14 0h12v6H14v-6zm14 8h12v6H28v-6zm-14 0h12v6H14v-6zm28 0h12v6H42v-6zm14-8h12v6H56v-6zm0-8h12v6H56v-6zm14 8h12v6H70v-6zm0 8h12v6H70v-6zM14 24h12v6H14v-6zm14-8h12v6H28v-6zM14 8h12v6H14V8zM0 8h12v6H0V8z' fill='%231c2127' fill-opacity='0.4' fill-rule='evenodd'/%3E%3C/svg%3E");
}

footer {
  display: flex;
  padding: 25px 35px;
  flex-direction: column;
  margin-top: -1px;
}

footer .socialmedia {
  display: flex;
  justify-content: center;
}

footer .socialmedia .instagram,
footer .socialmedia .linkedin {
  background: white;
  color: #1E282C;
  border-radius: 100%;
  padding: 0.5rem;
  line-height: 0;
  transition: background 150ms ease-in-out;
}

footer .socialmedia .instagram:hover,
footer .socialmedia .linkedin:hover {
  background: #E44A2F;
}

footer .socialmedia .instagram svg,
footer .socialmedia .linkedin svg {
  height: 1.25rem;
  width: 1.25rem;
}

footer .socialmedia .facebook {
  color: white;
  transition: color 150ms ease-in-out;
}

footer .socialmedia .facebook:hover {
  color: #E44A2F;
}

footer .socialmedia .facebook svg {
  width: 2.25rem;
  height: 2.25rem;
}

footer ul {
  display: flex;
  align-self: baseline;
  margin: 0 auto;
  list-style-type: none;
}

@media (max-width: 765px) {
  footer ul {
    flex-direction: column;
  }
  footer ul li {
    margin-bottom: 1rem;
    text-decoration: underline;
    text-align: center;
  }
}

@media (min-width: 765px) {
  footer ul li:not(:last-child) {
    margin-right: 1rem;
  }
}

.hero-video {
  padding: 0;
  width: 700px;
  height: 395px;
  position: relative;
  overflow: hidden;
  border: none;
}

.hero-video iframe {
  position: absolute;
  top: -1px;
  left: -1px;
  width: 101%;
  height: 101%;
  border-radius: 12px;
  overflow: hidden;
}

@media (max-width: 1000px) {
  .hero-video {
    width: 90vw;
    height: 50vw;
  }
}

.student-saying-card {
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
  padding: 0 0.5rem;
  align-items: center;
}

.student-saying-card img {
  margin-right: 1.5rem;
  height: 70px;
  width: 70px;
  border-radius: 100%;
  border: 1px solid #1E282C;
  flex-shrink: 0;
  background-size: cover;
}

.student-saying-card .student {
  display: flex;
  align-items: center;
}

@media (max-width: 565px) {
  .student-saying-card .student {
    flex-direction: column;
    text-align: center;
  }
  .student-saying-card .student img {
    margin-bottom: 1.5rem;
  }
}

.student-saying-card .student > div {
  margin-bottom: 0.25rem;
}

.student-saying-card .student > div p {
  margin-top: 0;
}

.map {
  height: 565px;
  position: relative;
}

.map #vienna-map {
  height: 133.333333333%;
  width: 133.333333333%;
  transform: scale(0.75);
  position: absolute;
  left: -16.6666666667%;
  top: -16.6666666667%;
  pointer-events: none;
}

.map .contact-card {
  position: relative;
  top: 59.5px;
  max-width: 375px;
  height: 446px;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  background-image: url("../img/weX-contact-card.jpg");
  background-size: cover;
  font-style: normal;
}

.map .contact-card h3 {
  font-size: 16px;
  margin-top: 0.75rem;
  font-weight: 700;
}

.map .contact-card p {
  margin-top: 0;
}

nav {
  display: flex;
  position: relative;
  justify-content: space-between;
}

nav .logo {
  height: 24px;
  align-self: center;
}

nav .logo img {
  height: 100%;
}

nav .local-links {
  display: flex;
  align-self: baseline;
}

nav .local-links li {
  list-style-type: none;
}

nav .local-links li:not(:last-child) {
  margin-right: 50px;
}

@media (max-width: 1200px) {
  nav .local-links {
    display: none;
  }
}

nav .hamburger {
  padding: 9px;
  display: none;
}

@media (max-width: 565px) {
  nav .hamburger {
    display: block;
  }
}

nav .buttons {
  text-align: right;
  align-self: baseline;
  transition: opacity 150ms ease-in-out;
  display: flex;
}

@media (max-width: 565px) {
  nav .buttons {
    border-radius: 8px;
    box-shadow: 0 15px 46px 0 rgba(0, 0, 0, 0.41);
    overflow: hidden;
    position: absolute;
    right: 1.25rem;
    top: -1000rem;
    opacity: 0;
    width: 10rem;
    background-color: #69616B;
    flex-direction: column;
  }
  nav .buttons > *:not(.to-edges) {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }
  nav .buttons > .to-edges:first-child + *:not(.to-edges),
  nav .buttons > *:first-child:not(.to-edges) {
    margin-top: 1.25rem;
  }
  nav .buttons > *:last-child:not(.to-edges) {
    margin-bottom: 1.25rem;
  }
  nav .buttons.card\:sm > *:not(.to-edges) {
    margin-left: 1rem;
    margin-right: 1rem;
  }
  nav .buttons.card\:sm > .to-edges:first-child + *:not(.to-edges),
  nav .buttons.card\:sm > *:first-child:not(.to-edges) {
    margin-top: 0.75rem;
  }
  nav .buttons.card\:sm > *:last-child:not(.to-edges) {
    margin-bottom: 0.75rem;
  }
  nav .buttons > *:not(.to-edges) {
    margin-left: 1rem;
    margin-right: 1rem;
  }
}

@media (max-width: 565px) {
  nav .hamburger.is-active + .buttons {
    opacity: 1;
    top: 120%;
  }
}

div.hero,
div.hero\:max {
  display: flex;
  flex-direction: column;
}

div.hero.hero\:max,
div.hero\:max.hero\:max {
  height: 100vh;
}

div.hero .stage,
div.hero\:max .stage {
  margin-top: 15vh;
}

@media (max-width: 765px) {
  div.hero .stage,
  div.hero\:max .stage {
    margin-top: 10vh;
  }
  div.hero .stage h1,
  div.hero\:max .stage h1 {
    margin-bottom: 7vh;
  }
  div.hero .stage .container\:columns > *,
  div.hero\:max .stage .container\:columns > * {
    margin-bottom: 1rem;
  }
}

div.digital-minds {
  background-image: url("data:image/svg+xml,%3Csvg width='84' height='48' viewBox='0 0 84 48' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 0h12v6H0V0zm28 8h12v6H28V8zm14-8h12v6H42V0zm14 0h12v6H56V0zm0 8h12v6H56V8zM42 8h12v6H42V8zm0 16h12v6H42v-6zm14-8h12v6H56v-6zm14 0h12v6H70v-6zm0-16h12v6H70V0zM28 32h12v6H28v-6zM14 16h12v6H14v-6zM0 24h12v6H0v-6zm0 8h12v6H0v-6zm14 0h12v6H14v-6zm14 8h12v6H28v-6zm-14 0h12v6H14v-6zm28 0h12v6H42v-6zm14-8h12v6H56v-6zm0-8h12v6H56v-6zm14 8h12v6H70v-6zm0 8h12v6H70v-6zM14 24h12v6H14v-6zm14-8h12v6H28v-6zM14 8h12v6H14V8zM0 8h12v6H0V8z' fill='%231c2127' fill-opacity='0.4' fill-rule='evenodd'/%3E%3C/svg%3E");
  padding-top: 2.5rem;
}

div.digital-minds .light-bg-break {
  background-image: url("data:image/svg+xml,%3Csvg width='84' height='48' viewBox='0 0 84 48' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 0h12v6H0V0zm28 8h12v6H28V8zm14-8h12v6H42V0zm14 0h12v6H56V0zm0 8h12v6H56V8zM42 8h12v6H42V8zm0 16h12v6H42v-6zm14-8h12v6H56v-6zm14 0h12v6H70v-6zm0-16h12v6H70V0zM28 32h12v6H28v-6zM14 16h12v6H14v-6zM0 24h12v6H0v-6zm0 8h12v6H0v-6zm14 0h12v6H14v-6zm14 8h12v6H28v-6zm-14 0h12v6H14v-6zm28 0h12v6H42v-6zm14-8h12v6H56v-6zm0-8h12v6H56v-6zm14 8h12v6H70v-6zm0 8h12v6H70v-6zM14 24h12v6H14v-6zm14-8h12v6H28v-6zM14 8h12v6H14V8zM0 8h12v6H0V8z' fill='%23e7e8ea' fill-opacity='0.4' fill-rule='evenodd'/%3E%3C/svg%3E");
}

div.digital-minds .logo {
  display: block;
  height: 29px;
}

div.digital-minds .cwp-graphic {
  position: relative;
}

div.digital-minds .cwp-graphic h3 {
  color: #F86754;
}

div.digital-minds .cwp-graphic img.cube {
  width: 288px;
}

@media (max-width: 1150px) {
  div.digital-minds .cwp-graphic .classroom-arc,
  div.digital-minds .cwp-graphic .workshop-arc,
  div.digital-minds .cwp-graphic .project-arc {
    display: none;
  }
  div.digital-minds .cwp-graphic img.cube {
    display: block;
    margin: 0 auto;
  }
  div.digital-minds .cwp-graphic h3 {
    margin-top: 1.5rem;
  }
}

@media (min-width: 1150px) {
  div.digital-minds .cwp-graphic {
    height: 570px;
  }
  div.digital-minds .cwp-graphic img.cube {
    position: absolute;
    top: 40px;
    left: 50%;
    margin-left: -144px;
  }
  div.digital-minds .cwp-graphic .classroom {
    width: 260px;
    position: absolute;
    top: 62px;
    left: 20px;
  }
  div.digital-minds .cwp-graphic .classroom h3 {
    text-align: center;
  }
  div.digital-minds .cwp-graphic .workshop {
    width: 260px;
    position: absolute;
    top: 5px;
    right: 0;
  }
  div.digital-minds .cwp-graphic .project {
    width: 320px;
    position: absolute;
    bottom: 0;
    left: 50%;
    margin-left: -118px;
  }
  div.digital-minds .cwp-graphic .classroom-arc {
    position: absolute;
    left: 146px;
    top: 13px;
  }
  div.digital-minds .cwp-graphic .workshop-arc {
    position: absolute;
    right: 270px;
    top: 12px;
  }
  div.digital-minds .cwp-graphic .project-arc {
    position: absolute;
    top: 310px;
    left: 306px;
  }
}

div.digital-minds .container\:slanted-rotation > div {
  height: 1.5rem;
}

div.digital-minds .jonas-and-monica {
  position: relative;
  z-index: 1;
}

div.digital-minds .jonas-and-monica img {
  height: 440px;
  position: absolute;
}

div.digital-minds .jonas-and-monica img.jonas {
  top: -200px;
  left: -280px;
}

div.digital-minds .jonas-and-monica img.monica {
  top: -350px;
  right: -320px;
}

@media (max-width: 1700px) {
  div.digital-minds .jonas-and-monica img.jonas {
    left: -10vw;
    top: -300px;
  }
  div.digital-minds .jonas-and-monica img.monica {
    right: -13vw;
  }
}

@media (max-width: 1150px) {
  div.digital-minds .jonas-and-monica img {
    display: none;
  }
}

div.digital-minds .buy-row {
  position: relative;
  padding-bottom: 6rem;
}

div.digital-minds .buy-row .decoration-images {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;
}

div.digital-minds .buy-row .decoration-images .heather {
  position: absolute;
  height: 440px;
  bottom: 0;
}

@media (min-width: 1000px) {
  div.digital-minds .buy-row .decoration-images .heather {
    left: 0;
  }
}

@media (max-width: 1000px) {
  div.digital-minds .buy-row .decoration-images .heather {
    right: 80vw;
  }
}

div.digital-minds .buy-row .decoration-images .blue-crystal {
  position: absolute;
  width: 135px;
  bottom: 450px;
}

@media (min-width: 1000px) {
  div.digital-minds .buy-row .decoration-images .blue-crystal {
    right: 0;
  }
}

@media (max-width: 1000px) {
  div.digital-minds .buy-row .decoration-images .blue-crystal {
    left: 85vw;
  }
}

div.digital-minds .buy-row .decoration-images .suitcase {
  position: absolute;
  width: 130px;
  bottom: -130px;
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

@media (min-width: 1000px) {
  div.digital-minds .buy-row .decoration-images .suitcase {
    right: -2px;
  }
}

@media (max-width: 1000px) {
  div.digital-minds .buy-row .decoration-images .suitcase {
    left: 78vw;
  }
}

div.digital-minds .buy-row .logo {
  height: 18px;
}

div.digital-minds .buy-row .card\:light {
  max-width: 315px;
}

ul {
  list-style-type: '–';
  padding-left: 0.5rem;
}

ul li {
  padding-left: 0.5rem;
}

ul.column-list {
  display: flex;
  flex-wrap: wrap;
}

ul.column-list li {
  flex: 1 14rem;
}

ul.check-list {
  list-style: none;
}

ul.check-list li {
  position: relative;
  padding-left: 2.4rem;
  padding-top: 0.1rem;
  margin-bottom: 0.7rem;
}

ul.check-list li::before {
  content: "";
  display: inline-block;
  height: 1.5rem;
  width: 1.5rem;
  position: absolute;
  top: 0rem;
  left: 0rem;
  background-image: url(http://placehold.it/32?text=fallback);
  background-image: url(../img/primary-check-list-icon.svg);
  background-size: contain;
  background-repeat: no-repeat;
  margin-right: 0.5em;
}

.siema-container {
  display: flex;
  align-items: stretch;
  flex-direction: row;
}

.siema-container a.siema-prev,
.siema-container a.siema-next {
  background: none;
  border: none;
  outline: none;
  box-shadow: none;
  color: white;
  display: flex;
  align-items: center;
}

.siema-container a.siema-prev:hover,
.siema-container a.siema-next:hover {
  cursor: pointer;
}

.siema-container a.siema-prev {
  transform: rotate(180deg);
}

.siema-container .siema {
  margin: 0 1rem;
}

.siema-container .siema > div {
  display: flex;
  align-items: center;
}

.siema-page-indicator {
  text-align: center;
}

.siema-page-indicator button {
  margin: 0 0.25rem;
  border-radius: 100%;
  height: 0.5rem;
  width: 0.5rem;
}

.accordion {
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  cursor: pointer;
  padding: 0;
}

.accordion input {
  display: none;
}

.accordion label {
  padding: 1rem 1.5rem;
  display: flex;
  flex-direction: row;
  cursor: pointer;
  font-weight: 700;
}

.accordion input + label + div {
  display: none;
  padding: 0.5rem 1.5rem 1.5rem;
  margin-top: -1rem;
}

.accordion input + label > .handle-open {
  display: none;
  margin-left: 0.5rem;
}

.accordion input + label > .handle-closed {
  display: block;
  margin-left: 0.5rem;
}

.accordion input:checked + label + div {
  display: block;
}

.accordion input:checked + label > .handle-open {
  display: block;
}

.accordion input:checked + label > .handle-closed {
  display: none;
}

.accordion .index {
  width: 1.5rem;
  flex-shrink: 0;
}

.accordion .question {
  flex: 1;
}

.accordion img {
  width: 100%;
}

.ninja-accordion {
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  cursor: pointer;
  padding: 0;
}

.ninja-accordion input {
  display: none;
}

.ninja-accordion label {
  padding: 1rem 0;
  display: flex;
  flex-direction: row;
  cursor: pointer;
}

.ninja-accordion input + label + div {
  display: none;
  padding: 0.5rem 0.5rem 1.5rem;
  margin-top: -1rem;
}

.ninja-accordion input + label > .handle-open {
  display: none;
  margin-left: 0.5rem;
}

.ninja-accordion input + label > .handle-closed {
  display: block;
  margin-left: 0.5rem;
}

.ninja-accordion input:checked + label + div {
  display: block;
}

.ninja-accordion input:checked + label > .handle-open {
  display: block;
}

.ninja-accordion input:checked + label > .handle-closed {
  display: none;
}

.ninja-accordion .index {
  width: 1.5rem;
  flex-shrink: 0;
}

.ninja-accordion .question {
  flex: 1;
  text-decoration: underline;
}

.banner {
  --height: 2rem;
  display: block;
  z-index: 9998;
  top: calc(100vh - var(--height));
  height: var(--height);
  line-height: var(--height);
}

@media (max-width: 765px) {
  .banner {
    padding-bottom: var(--height);
    top: calc(100vh - 2 * var(--height));
    height: auto;
  }
}
